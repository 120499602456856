* {
  font-family: hoss-round,sans-serif;
  font-style: normal;
}

.App {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
}

#root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("https://ipfs.io/ipfs/QmT6D2d8B6mBcSthAp5bG1QV9G7LgbaMwSARyK4VU42NL2");
  background-size: cover;
  background-position: center;
}

.app-header {
  position: fixed;
  top: 1em;
  color: white;
  font-weight: 600;
}

.app-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 25px rgba(0,0,0,0.15);
  padding: 2em 3em 6em 3em;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.15);
  transition: 0.2s ease;
  overflow: hidden;
}

.app-modal:hover {
  box-shadow: 0px 0px 50px rgba(0,0,0,0.25);
  border-radius: 25px;
  border: 3px solid rgba(0,0,0,1);
}

.referral {
  color: black;
}

.referral a {
  color: rgba(0,0,0,0.5);
  text-decoration: underline;
}

.referral a:hover {
  color: rgba(0,0,0,1);
  text-decoration: underline;
}

.identicon {
  border-radius: 100px;
  transform: translateY(3px);
  margin-bottom: -4px;
}

.ant-btn {
  font-family: hoss-round,sans-serif;
  font-weight: 500 !important;
  font-style: normal;
  transition: 0.2s ease !important;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  background: rgba(0,0,0,1) !important;
  color: white !important;
  border-color: black !important;
}

.ant-typography:hover {
  text-decoration: underline;
}

.ant-typography-copy {
  color: rgba(0,0,0,0.25) !important;
}

.ant-typography-copy:hover {
  color: rgba(0,0,0,0.75) !important;
}

.ens {
  position: relative;
}

.ens::before {
  content: "ENS name... nice.";
  font-size: 0.4em !important;
  font-weight: 500;
  color: rgba(0,0,0,0.25);
  position: absolute;
  bottom: -1.5em;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: 0.5s ease;
}

.ens:hover::before {
  opacity: 1;
}

.auth-button .ant-btn {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border: none;
  border-top: 1px solid rgba(0,0,0,0.25);
  border-radius: 0px;
  padding: 0.75em;
  height: auto;
  font-size: 1.25em !important;
  transition: 0.2s ease !important;
}

.auth-button .ant-btn:hover {
  /* background: rgba(0,0,0,0.15) !important;
  color: black !important; */
}

.auth-error {
  color: rgb(204, 52, 52) !important;
  font-weight: 500 !important;
  margin-top: 0.5em;
}

.auth-success {
  color: rgb(45, 197, 108) !important;
  font-weight: 500 !important;
  margin-top: 0.5em;
}